<template>
  <div>
    <NuxtLink
      to="/app/billing"
      class="flex items-center gap-2 px-4 py-1.5 rounded-full bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out cursor-pointer"
      :class="{'opacity-70': creditsStore.isLoading}"
    >
      <div class="text-gray-500">
        <DocumentPlusIcon class="w-4 h-4" />
      </div>
      <span class="font-medium min-w-[2ch] text-gray-700 text-sm">
        {{ formattedCredits }}
      </span>
    </NuxtLink>
  </div>
</template>

<script setup>
import {DocumentPlusIcon} from '@heroicons/vue/24/outline';

const creditsStore = useCreditsStore();
const {availableCredits, isLoading} = storeToRefs(creditsStore);

const formattedCredits = computed(() => {
  if (isLoading.value) return '...';
  return new Intl.NumberFormat().format(availableCredits.value);
});

const tooltipText = computed(() => {
  if (isLoading.value) return 'Loading credits...';
  return `${formattedCredits.value} credits available`;
});

// Initialize credits and cleanup
onMounted(() => {
  creditsStore.fetchCredits();
  creditsStore.subscribeToCredits();
});

onUnmounted(() => {
  creditsStore.unsubscribeFromCredits();
});
</script>

<style scoped>
/* Optional: Add any custom styles that can't be handled by Tailwind */
.v-tooltip {
  z-index: 100;
}
</style>

<template>
  <div>
    <div
      class="flex items-center px-6 py-3 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0 min-w-[36rem]"
    >
      <div class="min-w-full">
        <label for="search" class="sr-only">Search documents…</label>
        <div class="relative">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            v-model.trim="searchTerm"
            ref="searchInput"
            @input="debouncedSearch"
            name="search"
            class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            placeholder="Search documents…"
            type="search"
            :oncommit="unsetSearch"
            @focus="showResults = true"
            @blur="hideResults"
          />
          <div
            v-if="(searching || showResults) && searchTerm && searchTerm.length > 0"
            class="absolute z-10 mt-1 w-full rounded border bg-white sm:text-sm shadow-lg overflow-hidden"
          >
            <ul>
              <li
                v-if="searching"
                class="flex flex-row justify-center flex-wrap px-4 py-2 leading-5 bg-slate-50"
              >
                <div class="text-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-4 h-4 text-gray-200 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li
                v-if="!searching && filteredDocuments && filteredDocuments.length > 0"
                v-for="filteredDocument in filteredDocuments.slice(0, 10)"
                :key="filteredDocument.id"
                class="left-0 flex cursor-pointer flex-row justify-between flex-wrap px-4 py-2 leading-5 hover:bg-slate-50"
                @click="openDocument(filteredDocument)"
              >
                <div class="sm:pl-5">
                  {{ filteredDocument.original_name }}
                </div>
                <!-- <div v-html="highlightMatch(filteredDocument.ocr_text)" class="sm:pl-5"></div> -->
                <div class="sm:px-2 text-sm text-gray-500">
                  <NuxtTime
                    :datetime="filteredDocument.created_at"
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                  />
                </div>
              </li>
              <li
                v-if="!searching && filteredDocuments && filteredDocuments.length > 10"
                class="left-0 flex flex-row justify-between flex-wrap px-4 py-2 leading-5 bg-slate-50"
              >
                <div class="pl-5 text-sm text-gray-500">
                  More than ten matching documents found. Please adjust your search.
                </div>
              </li>
              <li
                v-if="!searching && !filteredDocuments.length"
                class="left-0 flex flex-row justify-between flex-wrap px-4 py-2 leading-5 bg-slate-50"
              >
                <div class="pl-5 text-sm text-gray-500">
                  No documents found. Please modify your search and try again.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid';
import {debounce} from 'lodash-es'; // Ensure lodash-es is installed
import {ref, watch} from 'vue';

const documentStore = useDocumentStore();
const {filteredDocuments} = storeToRefs(documentStore);
const searching = ref(true);
const showResults = ref(false);
const searchTerm = ref('');
const searchInput = ref(null);

const searchDocuments = async term => {
  if (term.trim() === '') {
    return; // Avoid searching for empty strings
  }
  try {
    searching.value = true;
    await documentStore.searchDocuments(term);
  } catch (error) {
    console.error('Error searching documents:', error);
  } finally {
    searching.value = false;
  }
};

const debouncedSearch = debounce(() => searchDocuments(searchTerm.value), 300); // Adjust debounce time as needed

watch(searchTerm, newValue => {
  debouncedSearch();
});

const unsetSearch = () => {
  searchTerm.value = '';
  showResults.value = false;
  // Remove search objects from document store
  documentStore.unsetSearchDocuments();
  // Remove the focus from the input field with the id search
  searchInput.value.blur();
};

// Clear the search term once the user hits the escape button on the keyboard
window.addEventListener('keydown', event => {
  if (event.key === 'Escape') {
    unsetSearch();
  }
});

function hideResults() {
  // Delay hiding results to allow click event on results
  setTimeout(() => {
    showResults.value = false;
  }, 200);
}

const openDocument = document => {
  // Hide search results
  showResults.value = false;
  searchInput.value.blur();

  // Use router to push to route /document/1
  navigateTo({path: `/app/documents/${document.id}`});
};
</script>
